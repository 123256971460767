<template>
  <div class="app">
    <GoogleOneTapAuth />
    <slot />

    <ClientOnly>
      <TheBadges />
      <ModalContainer />
      <SafariPushWindowContainer v-if="uiStore.isSafariPushDialogShow" />
      <TypoFormContainer v-if="typoStore.isShowForm" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
  import { useMagicKeys, whenever } from '@vueuse/core';
  import ModalContainer from '@devhacker/shared/plugins/modal/ModalContainer.vue';
  import GoogleOneTapAuth from '~/components/GoogleOneTapAuth.vue';
  import TheBadges from '~/components/Pusher/TheBadges/TheBadges.vue';
  import { useUIStore } from '~/store/ui';
  import { useTypoStore } from '~/store/typos';
  import TypoFormContainer from '~/containers/TypoFormContainer.vue';
  import SafariPushWindowContainer from '~/containers/SafariPushWindowContainer/SafariPushWindowContainer.vue';
  import { type AdfoxParams, useAdfoxStore } from '~/store/adfoxParams';
  import { isRetina } from '~/utils';
  import buildBuildInfo from '@devhacker/shared/features/BuildInfo/buildBuildInfo';
  import { useIndexStore } from '~/store';
  import { pushBadge } from '~/components/Pusher/websockets-functions';

  const indexStore = useIndexStore();
  const typoStore = useTypoStore();
  const uiStore = useUIStore();
  const adfoxStore = useAdfoxStore();
  const { Ctrl_Enter } = useMagicKeys();

  watch(
    () => adfoxStore.currentAdfox,
    (adfoxValue: AdfoxParams) => {
      if (import.meta.server) return;

      Object.assign(window, {
        ...adfoxValue,
        lh_adfox_rtn: isRetina(),
      });
    },
    { immediate: true },
  );

  onMounted(() => {
    // решение на случай если это старый пользователь
    // у которого в кеше хранится значение 'manual'
    // нужно для того чтобы навигация работала как MPA
    window.history.scrollRestoration = 'auto';

    // выводим версию билда в консоль
    Object.assign(window, {
      buildInfo: buildBuildInfo(),
    });
  });

  const isSearchVisible = computed(() => indexStore.visibleModalWindows.includes('search'));

  // whenever не пораждает дубликатов в отличие от watch
  whenever(Ctrl_Enter, () => {
    if (isSearchVisible.value) return;

    const selection = window.getSelection();
    const text = selection?.toString();
    if (!text) return;

    if (text.length > 300) {
      return pushBadge({
        content: 'Пожалуйста, выделите конкретное слово или предложение с ошибкой.',
        isError: true,
      });
    }

    const context = (
      selection?.getRangeAt(0)?.startContainer?.parentNode?.textContent ?? ''
    ).replace(text, `<b>${text}</b>`);
    typoStore.addTypoTextAndShowForm(text, context);
  });

  useHead({
    link: [
      // Analytics
      { rel: 'dns-prefetch', href: 'https://www.googletagmanager.com' },
      { rel: 'preconnect', href: 'https://www.googletagmanager.com' },
      { rel: 'dns-prefetch', href: 'https://top-fwz1.mail.ru' },
      { rel: 'preconnect', href: 'https://top-fwz1.mail.ru' },
      { rel: 'dns-prefetch', href: 'https://mc.yandex.ru' },
      { rel: 'preconnect', href: 'https://mc.yandex.ru' },

      // Рекламные сети
      { rel: 'dns-prefetch', href: 'https://static.criteo.net' },
      { rel: 'preconnect', href: 'https://static.criteo.net' },
      { rel: 'dns-prefetch', href: 'https://exchange.buzzoola.com' },
      { rel: 'preconnect', href: 'https://exchange.buzzoola.com' },
      { rel: 'dns-prefetch', href: 'https://pb.adriver.ru' },
      { rel: 'preconnect', href: 'https://pb.adriver.ru' },
      { rel: 'dns-prefetch', href: 'https://ad.mail.ru' },
      { rel: 'preconnect', href: 'https://ad.mail.ru' },
      { rel: 'manifest', href: 'https://lifehacker.ru/manifest.json' },
    ],

    script: [
      {
        // скрипты асинхронные, поэтому нужно создать массимы/объекты под них
        // обычно достаточно window.yaContextCb = window.yaContextCb || []
        // но у нас реклама что внутри статей использует внутри adfoxCode.hbCallbacks
        // поэтому мы ее так-же добавляем, согласно документации
        // хоть у нас и не бесконечный скрол, но действия нужны те-же
        // https://yandex.ru/support2/adfox/ru/monetization/script-features#endless-scroll
        tagPriority: 'critical',
        innerHTML:
          'window.yaContextCb = window.yaContextCb || [];\n' +
          'window.Ya || (window.Ya = {});\n' +
          'window.Ya.adfoxCode || (window.Ya.adfoxCode = {});\n' +
          'window.Ya.adfoxCode.hbCallbacks || (window.Ya.adfoxCode.hbCallbacks = []);',
      },
      {
        // https://yandex.ru/support/partner/web/products-rtb/partner-code.html
        tagPriority: 'critical',
        src: 'https://yandex.ru/ads/system/context.js',
        crossorigin: 'anonymous',
        async: true,
      },
      {
        // https://yandex.ru/support/adfox-sites/monetization/hb-change-code.html
        tagPriority: 'critical',
        src: 'https://yandex.ru/ads/system/header-bidding.js',
        async: true,
      },
      {
        src: 'https://accounts.google.com/gsi/client',
        tagPosition: 'bodyClose',
        async: true,
      },
    ],
  });
</script>

<style lang="scss">
  // порядок импорта имеет значение
  @use '~/scss/inc/_base.amp' as *;
  @use '~/scss/inc/base' as *;
  @use '~/scss/inc/_code.amp' as *;
  @use '~/scss/inc/_quasar' as *;
  //@use '~/scss/inc/_perfect-scrollbar' as *;
  @use '~/scss/inc/_html-widget' as *;

  @view-transition {
    navigation: auto;
  }

  .modal-fade {
    &-enter-active,
    &-leave-active {
      opacity: 0;
    }
  }

  // * У лх есть 2 основных цвета - лосовевый, как акцентный. и синеватый для ссылок, кнопок и пр. поэтому есть разделение на main-color & main-color-2.
  // * аналогично для ховер цветов. для main-color - hover-color. для main-color-2 - hover-color-2
  // * цветы были введены по причине того, что иногда требуется перекрасить отдельный раздел под свои цвета, благодаря переменным это гораздо проще
  :root {
    --main-color: #{$main};
    --main-color-2: #2962f9;
    --preview-header-color: #a0270f;
    --hover-color: #d44b3f;
    --hover-color-2: #264eb1;
    --bg-color-substrate: #fbf8f8;
    --bg-color-substrate-2: #f3f5fc;
    --bg-color-substrate-3: #f3f5fc;
    --disabled-color: #d4d7de;
  }

  .wpappbox {
    display: none;
  }

  // костыль для партнерского скрипта
  .ub-sticky-side-right {
    pointer-events: none;
  }

  // костыль для google-popup
  // https://developers.google.com/identity/gsi/web/guides/change-position?hl=ru
  // Атрибут data-prompt_parent_id игнорируется в мобильных веб-браузерах.
  // возможно поможет вариант со своим контейнером https://gist.github.com/srestraj/c61d0a025f53ab7f99bd875eace5cc84
  #credential_picker_iframe {
    z-index: 9999999999 !important;
  }

  // Костыль для adfox, неправильно расчитывает отступ от меню
  // Задается в singleArticle.ts в mounted
  body.one-level-menu > .adfox-banner-background {
    top: 0px !important;
  }

  // В глобальных стилях для более быстрой загрузки
  .test-pixel,
  .test-pixel-observer {
    height: 1px;
    width: 1px;
    position: absolute;
    opacity: 0;
    font-size: 0;
  }
</style>
